<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>SOF ID</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                :disabled="true"
                v-model="table2.data.code"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedWarehouse"
                :options="optionWarehouse"
                :multiple="false"
                :disabled="editOrShow"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLableWarehouse"
                @select="handleSelectWarehouse()"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Request Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="date"
                type="date"
                :disabled="editOrShow"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Count Type</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedCountType"
                :options="optionCountType"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                :disabled="editOrShow"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelCountType"
                @select="handleSelectItem()"
              />
            </div>
          </div>
        </div>
      </div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="datas"
        :max-items="table.length"
        v-if="selectedCountType.id == 1"
      >
        <template slot="thead">
          <vs-th class="w-1/3" sort-key="sku_code">SKU Code</vs-th>
          <vs-th class="w-1/3" sort-key="item_name">SKU Description </vs-th>
          <vs-th class="w-1/3 text-center" sort-key="warehouse_area"
            >Warehouse Area</vs-th
          >
        </template>
        <template>
          <vs-tr :data="tr" :key="indexTr" v-for="(tr, indexTr) in datas">
            <vs-td class="w-1/5">
              <div>
                <vs-input
                  class="w-full"
                  v-model="tr.sku_code"
                  type="data"
                  :disabled="editOrShow"
                  v-validate="'required'"
                /></div
            ></vs-td>
            <vs-td class="w-1/5" :data="tr.name"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.name"
                />
              </div>
            </vs-td>
            <template class="w-3/5" v-if="Array.isArray(tr.sku_item)">
              <div v-for="(skuItem, skuIndex) in tr.sku_item" :key="skuIndex">
                <vs-td :data="skuItem.warehouse_area_name">
                  <vs-input
                    class="w-full"
                    type="input"
                    v-model="skuItem.warehouse_area_name"
                    :disabled="true"
                  />
                </vs-td>
              </div>
            </template>
            <vs-td class="w-1/5"> </vs-td>
          </vs-tr>

          <tr
            v-for="index in addTr"
            :key="index"
            style="background: transparent"
          >
            <td></td>
          </tr>
        </template>
      </vs-table>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
        v-if="selectedCountType.id == 2"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th sort-key="sku_code">SKU Code</vs-th>
          <vs-th sort-key="item_name">SKU Description </vs-th>
          <vs-th>
            <vs-th class="w-1/4" sort-key="warehouse_area"
              >Warehouse Area</vs-th
            >
          </vs-th>
          <vs-th v-if="selectedCountType.id == 1" sort-key=""></vs-th>
        </template>
        <template>
          <vs-tr :data="tr" :key="indexTr" v-for="(tr, indexTr) in table.data">
            <vs-td :data="tr.sku_code">
              <div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.sku_code"
                /></div
            ></vs-td>
            <vs-td :data="tr.name"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.name"
                />
              </div>
            </vs-td>
            <template v-if="Array.isArray(tr.sku_item)">
              <div v-for="(skuItem, skuIndex) in tr.sku_item" :key="skuIndex">
                <vs-td :data="skuItem.warehouse_area_name">
                  <vs-input
                    class="w-full"
                    type="input"
                    v-model="skuItem.warehouse_area_name"
                    :disabled="true"
                  />
                </vs-td>
              </div>
            </template>
          </vs-tr>
          <tr
            v-for="index in addTr"
            :key="index"
            style="background: transparent"
          >
            <td></td>
          </tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
        v-if="selectedCountType.id == 2"
      />
      <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="SubmitForm()" v-if="!editOrShow">
            Save
          </vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  mounted() {
    this.handleCheckEditOrShow();
    this.getWarehouse();
  },

  data() {
    return {
      editOrShow: false,
      table: this.tableDefaultState(),
      table2: this.tableDefaultState(),
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      addTr: 3,
      closeTr: 3,
      optionItemSKU: [],
      selectItemSKU: [],
      optionWarehouse: [],
      selectedWarehouse: {},
      selectedWarehouseMirror: {},
      optionCountType: [
        {
          name: "SAMPLING",
          id: 1,
        },
        {
          name: "FULL",
          id: 2,
        },
      ],
      selectedCountType: { name: "", id: 0 },
      isFULL: false,
      note: "",
      code: "",
      datas: [
        {
          sku_code: "",
          name: "",
          sku_item: [
            {
              warehouse_area_name: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "stock-opname-staff" });
      this.$vs.loading.close();
    },

    handleCheckEditOrShow() {
      if (this.$route.query.action == "Show") {
        this.editOrShow = true;
      }
    },
    handleSelectWarehouse() {
      if (this.selectedWarehouseMirror != null) {
        this.selectedWarehouseMirror = this.selectedWarehouse;
        this.datas = [
          {
            sku_code: "",
            name: "",
            sku_item: [
              {
                warehouse_area_name: "",
                unit: "",
                quantity: "",
              },
            ],
          },
        ];
        this.selectItemSKU = [];
        this.getItems();
      } else if (this.selectedWarehouse.id != this.selectedWarehouseMirror.id) {
        this.datas = [
          {
            sku_code: "",
            name: "",
            sku_item: [
              {
                warehouse_area_name: "",
                unit: "",
                quantity: "",
              },
            ],
          },
        ];
        this.selectItemSKU = [];
        this.getItems();
        this.selectedWarehouseMirror = this.selectedWarehouse;
      }
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/warehouse-pickup", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                this.selectedWarehouse = this.optionWarehouse[0];
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = {};
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          this.getData();
        });
    },
    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    customLabelCountType({ name }) {
      return `${name}`;
    },
    handleSelectItem() {
      if (this.selectedCountType.id == 1) {
        this.isFULL = true;
      }
      this.getItems();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getItems();
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getItems();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getItems();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getItems();
    },
    getItems() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/allItemStock", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.selectedWarehouse.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.data = [];
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.optionItemSKU = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSearchItemSKU(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getItems();
    },
    handleSelectItemSKU(item) {
      this.selectedItemSKU = item;
      console.log("ini selected", this.selectedItemSKU);
      let items = [];
      for (let index = 0; index < item.sku_item.length; index++) {
        items.push({
          warehouse_area_name: item.sku_item[index].warehouse_area_name,
          unit: item.sku_item[index].unit,
          quantity: item.sku_item[index].quantity,
        });
      }

      if (this.datas[this.datas.length - 1].sku_code == "") {
        this.datas[this.datas.length - 1].sku_code = item.sku_code;
        this.datas[this.datas.length - 1].name = item.name;
        this.datas[this.datas.length - 1].sku_item = items;
      } else {
        this.datas.push({
          id: this.selectItemSKU.id,
          sku_code: this.selectedItemSKU.sku_code,
          name: this.selectedItemSKU.name,
          sku_item: items,
        });
      }

      console.log("datas", this.datas);
    },
    customLabelItemSKU({ sku_code }) {
      return `${sku_code}`;
    },
    handleAddItemRef() {
      this.addTr++;
      this.datas.push({
        sku_code: "",
        name: "",
        sku_item: [
          {
            warehouse_area_name: "",
            unit: "",
            quantity: "",
          },
        ],
      });
    },
    handleRemoveItemRef(index) {
      this.selectItemSKU.splice(index, 1);
      this.datas.splice(index, 1);
      this.addTr--;
      console.log("selectItemSKU", this.selectItemSKU);
      console.log("datas", this.datas);
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachmentUpdate.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";

        let form = new FormData();
        form.append("stock_opname_id", parseInt(this.$route.query.id));
        for (let index = 0; index < this.fileAttachmentUpdate.length; index++) {
          form.append("attachments[]", this.fileAttachmentUpdate[index].File);
        }
        this.$vs.loading();
        this.$http
          .post("/api/wms/v1/stock-opname/update-or-delete-attachment", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "Data upload successfully",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              this.getData();
            }
            this.$vs.loading.close();
          });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = file.PathFile;

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(data) {
      console.log("data", data);
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Warning",
        text: "Are you sure to delete this data?",
        accept: () => {
          this.$vs.loading();

          this.$http
            .post("/api/wms/v1/stock-opname/update-or-delete-attachment", {
              id: data.ListID,
              stock_opname_id: parseInt(this.$route.query.id),
              status: "DELETE",
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Data deleted successfully",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.getData();
              }
              this.$vs.loading.close();
            });
        },
      });
    },
    SubmitForm() {
      let item_sku = [];
      for (let index = 0; index < this.selectItemSKU.length; index++) {
        item_sku.push(this.selectItemSKU[index].sku_code);
      }
      console.log("item_sku", item_sku);
      console.log("fileAttachment", this.fileAttachment);
      console.log("selectedWarehouse", this.selectedWarehouse.id);
      console.log("request_date", this.date);
      console.log("count_type", this.selectedCountType.name);
      console.log("note", this.note);

      let form = new FormData();
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", this.date);
      form.append("count_type", this.selectedCountType.name);
      form.append("note", this.note);
      form.append("code", this.code);
      for (let index = 0; index < item_sku.length; index++) {
        form.append("sku_code[]", item_sku[index]);
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/save", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Stock Opname has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.handleClose();
          this.$vs.loading.close();
        });
    },
    async getData() {
      this.$vs.loading();
      await this.$http
        .get("/api/wms/v1/stock-opname/edit", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.fileAttachment = [];
            this.fileAttachmentUpdate = [];
            this.table2.total = resp.data.total_record;
            this.table2.totalPage = resp.data.total_page;
            this.table2.totalSearch = resp.data.total_record_search;
            this.table2.length = resp.data.total_record_per_page;
            this.table2.data = resp.data;
            this.date = moment(resp.data.request_date).format("YYYY-MM-DD");
            this.note = resp.data.note;
            this.code = resp.data.code;

            this.optionWarehouse.forEach((element) => {
              if (element.id == this.table2.data.warehouse_id) {
                this.selectedWarehouse = element;
              }
            });

            this.optionCountType.forEach((element) => {
              if (element.name == this.table2.data.count_type) {
                this.selectedCountType = element;
              }
            });

            console.log("weee", this.table2.data);
            console.log("attachment", this.fileAttachment);
            for (
              let index = 0;
              index < resp.data.attachment_data.length;
              index++
            ) {
              this.fileAttachment.push({
                ListID: resp.data.attachment_data[index].id,
                NameFile: resp.data.attachment_data[index].file_name,
                PathFile: resp.data.attachment_data[index].file_url,
              });
            }
            if (this.selectedCountType.id == 1) {
              this.datas = [];

              for (let index = 0; index < resp.data.sku_item.length; index++) {
                this.datas.push({
                  sku_code: resp.data.sku_item[index].sku_code,
                  name: resp.data.sku_item[index].name,
                  sku_item: resp.data.sku_item[index].sku_item_by_warehouse,
                });

                this.selectItemSKU.push({
                  sku_code: resp.data.sku_item[index].sku_code,
                });
              }

              this.getItems();
              console.log("datas", this.datas);
            } else {
              this.getItems();
            }
            console.log("selectItemSKU", this.selectItemSKU);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  watch: {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
